import React, { useEffect, useState } from 'react';
import { getAllEvents } from '../services/EventService';
import { Link } from 'react-router-dom';

const EventList = () => {
  const [events, setEvents] = useState([]);

  const fetchEvents = async () => {
    try {
      const { data } = await getAllEvents();
      console.log('Fetched Events Data:', data); // Log the fetched data for debugging
      if (data && Array.isArray(data)) {
        setEvents(data); // Only set events if the data is an array
      } else {
        console.error("Data fetched is not an array:", data);
      }
    } catch (err) {
      console.error("Failed to fetch events:", err);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <div>
      <div className="text-center">
        <h1>Upcoming PTO Events & Fundraisers</h1>
        <p>All dates are tentative and subject to change.</p>
      </div>

      <div className="container text-center">
        <div className="row">
          {events.length > 0 ? events.map((event) => (
            <div className="col-md-6 mb-4" key={event.event_id}>
              <h2 className="text-primary">
                <Link to={`/events/${event.slug}`}>{event.eventName}</Link>
              </h2>
              <p>{event.description}</p>
              {event.eventDate && (
                <p>Event date: {new Date(event.eventDate).toLocaleDateString()}</p>
              )}
            </div>
          )) : (
            <div>No events to show.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventList;
