import React, { useState, useEffect } from 'react';
import { getFundraisingById, updateFundraising } from '../services/FundraisingService';
import { useParams, useNavigate } from 'react-router-dom';

const EditFundraising = () => {
    const { id } = useParams();
    const [fundraising, setFundraising] = useState({
        fundraisingTitle: '',
        fundraisingDescription: '',
        fundraisingDate: ''
    });
    const [message, setMessage] = useState('');
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFundraising = async () => {
          try {
            const { data } = await getFundraisingById(id, token);  // Use getFundraisingById instead of getFundraisingBySlug
            setFundraising(data);
          } catch (err) {
            console.error("Error fetching fundraising event:", err);
          }
        };
      
        fetchFundraising();
      }, [id, token]);
      

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateFundraising(id, fundraising, token);
            setMessage('Fundraising event updated successfully.');
            navigate('/admin/fundraising');
        } catch (err) {
            setMessage('Error updating fundraising event.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>Fundraising Title</label>
                <input
                    type="text"
                    value={fundraising.fundraisingTitle}
                    onChange={(e) => setFundraising({ ...fundraising, fundraisingTitle: e.target.value })}
                    required
                />
            </div>
            <div>
                <label>Fundraising Description</label>
                <textarea
                    value={fundraising.fundraisingDescription}
                    onChange={(e) => setFundraising({ ...fundraising, fundraisingDescription: e.target.value })}
                    required
                />
            </div>
            <div>
                <label>Fundraising Date</label>
                <input
                    type="date"
                    value={fundraising.fundraisingDate}
                    onChange={(e) => setFundraising({ ...fundraising, fundraisingDate: e.target.value })}
                    required
                />
            </div>
            <button type="submit">Update Fundraising</button>
            {message && <p>{message}</p>}
        </form>
    );
};

export default EditFundraising;
