import axios from 'axios';

const PUBLIC_API_URL = "http://localhost:8082/api/events";
const ADMIN_API_URL = "http://localhost:8082/admin/events";

export const getAllEvents = () => {
    return axios.get(`${PUBLIC_API_URL}`);
};

export const getEventBySlug = (slug) => {
    return axios.get(`${PUBLIC_API_URL}/slug/${slug}`);
};

export const createEvent = (eventData) => {
    const token = localStorage.getItem('token');  // Ensure the JWT token is stored correctly

    return axios.post(ADMIN_API_URL, eventData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`  // Token included in header
        }
    });
};
