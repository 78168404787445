import React from 'react';
import './Footer.css';  // Custom styles for the footer

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container text-center">
        <div className="social-icons">
          <a href="https://www.facebook.com/rosaleecarterespto" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-facebook fa-2x"></i> {/* Bootstrap Icon for Facebook */}
          </a>
          <a href="https://www.instagram.com/rlcpto/" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-instagram fa-2x"></i> {/* Bootstrap Icon for Instagram */}
          </a>
          <a href="https://x.com/i/flow/login?redirect_after_login=%2Frlc_coyotes" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-twitter fa-2x"></i> {/* Bootstrap Icon for Twitter */}
          </a>
          <a href="https://www.youtube.com/channel/UCXdGc6EzQuDPNViv7lYzuQg" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-youtube fa-2x"></i> {/* Bootstrap Icon for YouTube */}
          </a>
        </div>
        <p>Copyright 2024-25 RLC PTO. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
