import React, { useState } from 'react';
import './MembershipPage.css';
import LoginModal from './LoginModal';  // Import the LoginModal

const MembershipPage = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);  // State to control modal visibility

  // Handle the register button click to show the modal
  const handleRegisterClick = () => {
    setShowLoginModal(true);  // Show the login modal on click
  };

  const handleCloseLoginModal = () => {
    setShowLoginModal(false);  // Close the modal
  };

  return (
    <div className="membership-page">
      <div className="container">
        <h1 className="membership-title">Become a PTO Member</h1>
        <p className="membership-description">
          Join us in supporting our community and enhancing the education experience for all children. As a member, you'll be part of a dynamic team working to make a positive impact.
        </p>
        <div className="membership-benefits">
          <h2>Membership Benefits</h2>
          <ul>
            <li>Access to exclusive events and activities</li>
            <li>Opportunities to volunteer and contribute</li>
            <li>Support school initiatives and fundraising efforts</li>
          </ul>
        </div>
        {/* Updated button to trigger the login modal */}
        <button className="btn btn-primary register-btn" onClick={handleRegisterClick}>
          Register Now
        </button>
      </div>

      {/* Add the Login Modal component and control its visibility */}
      <LoginModal show={showLoginModal} handleClose={handleCloseLoginModal} />
    </div>
  );
};

export default MembershipPage;
