import React from 'react';

const FundraisingOverview = () => {
  return (
    <div className="container">
      <h1>RLC PTO Fundraising</h1>
      <p>We are excited for this school year and look forward to introducing ways to raise funds for the school.

One of our fundraising events will include Spirit Nights/Days where Coyote families (including parents, kids and RLC staff) can carry their school’s spirit from a PTO approved partner facility that provides entertainment and food. By supporting our Spirit Nights/Days, RLC PTO will receive a portion of the proceeds from the purchases Coyote families make at all above RLC PTO partner facilities.

Some of our other popular fundraising events include Coyote Fun Run, Holiday Shop, March Madness etc. that our RLC Staff and PTO organize for our Coyote families. Please look forward to supporting our PTO funded projects for our school by participating in these fundraiser events.

Above all, Cashless Fundraising is one of the easiest ways for our school to earn funds and show your spirit. As in the name - Cashless - when Coyote families and RLC patrons go about their normal shopping routines at various partner facilities (in physical stores as well as online), these partners donate a portion of the purchases back to our PTO. Click here to learn more about how to support RLC PTO through Cashless Fundraising campaign.

Lastly, Coyotes can be expressive and true to their nature by purchasing the newly introduced School Spirit Wear with many attractive and cool designs that make Spirit Wear Fridays truly fun. You can purchase School Spirit wear at the PTO run events in the school.

Feel free to reach us at the contacts below for any fundraising related questions. 

Rupali Mahajan (vp-fundraising@rlcpto.org)
VP of Fundraising</p>
      <p>Check the dropdown menu for individual fundraising events or contact us to find out how you can get involved.</p>
    </div>
  );
};

export default FundraisingOverview;
