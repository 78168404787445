// FundraisingListAdmin.js
import React, { useEffect, useState } from 'react';
import { getAllFundraisingAdmin, deleteFundraising } from '../services/FundraisingService';
import { Link } from 'react-router-dom';

const FundraisingListAdmin = () => {
    const [fundraisingList, setFundraisingList] = useState([]);
    const [message, setMessage] = useState('');
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchFundraising = async () => {
            try {
                const { data } = await getAllFundraisingAdmin(token);
                setFundraisingList(data);
            } catch (err) {
                console.error("Error fetching fundraising events:", err);
                setMessage("Error fetching fundraising events.");
            }
        };

        fetchFundraising();
    }, [token]);

    const handleDelete = async (id) => {
        try {
            await deleteFundraising(id, token);
            setMessage('Fundraising event deleted successfully.');
            setFundraisingList(fundraisingList.filter(item => item.fundraising_id !== id));
        } catch (err) {
            setMessage('Error deleting fundraising event.');
        }
    };

    return (
        <div className="container">
            <h1>Fundraising Events</h1>
            {message && <p>{message}</p>}
            <ul>
                {fundraisingList.length > 0 ? (
                    fundraisingList.map(fundraising => (
                        <li key={fundraising.fundraising_id}>
                            {fundraising.fundraisingTitle} - {new Date(fundraising.fundraisingDate).toLocaleDateString()}
                            <Link to={`/admin/fundraising/edit/${fundraising.fundraising_id}`}>
                                Edit
                            </Link>
                            <button onClick={() => handleDelete(fundraising.fundraising_id)}>Delete</button>
                        </li>
                    ))
                ) : (
                    <p>No fundraising events available.</p>
                )}
            </ul>
        </div>
    );
};

export default FundraisingListAdmin;
