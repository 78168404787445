import React, { useState } from 'react';
import axios from 'axios';

const AdminRoleAssignment = () => {
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');

  const handleAssignAdmin = async () => {
    try {
      const response = await axios.post(`/api/users/assignAdmin/${username}`);
      setMessage(response.data);
    } catch (error) {
      setMessage("Error assigning admin role. Please try again.");
    }
  };

  return (
    <div>
      <h3>Assign Admin Role</h3>
      <input 
        type="text" 
        placeholder="Enter Username" 
        value={username} 
        onChange={(e) => setUsername(e.target.value)} 
      />
      <button onClick={handleAssignAdmin}>Assign Admin Role</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default AdminRoleAssignment;
