import React, { useState } from 'react';
import { createEvent } from '../services/EventService';

const AddEvents = () => {
  const [eventName, setEventName] = useState('');
  const [description, setDescription] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [location, setLocation] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!eventDate) {
      alert('Event date is required');
      return;
    }

    const formData = new FormData();
    formData.append('eventName', eventName);
    formData.append('description', description);
    formData.append('eventDate', eventDate);
    formData.append('location', location);

    try {
      const response = await createEvent(formData);
      console.log('Event created successfully:', response.data);
    } catch (err) {
      console.error('Failed to create event:', err);
    }
  };

  return (
    <div>
      <h2>Add New Event</h2>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div>
          <label>Event Name:</label>
          <input
            type="text"
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Description:</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div>
  <label>Event Date:</label>
  <input
    type="date"  // Change from "datetime-local" to "date"
    value={eventDate}
    onChange={(e) => setEventDate(e.target.value)}
    required
  />
</div>

        <div>
          <label>Location:</label>
          <input
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
        </div>
      
        <button type="submit">Add Event</button>
      </form>
    </div>
  );
};

export default AddEvents;
