// FundraisingPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFundraisingBySlug } from '../services/FundraisingService';

const FundraisingPage = () => {
  const { slug } = useParams();
  const [fundraising, setFundraising] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchFundraising = async () => {
      try {
        const { data } = await getFundraisingBySlug(slug);
        setFundraising(data);
      } catch (err) {
        setError("Failed to fetch fundraising event. Please check the URL or try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchFundraising();
  }, [slug]);

  if (loading) {
    return <p>Loading fundraising details...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="container">
      <h1>{fundraising.fundraisingTitle}</h1>
      <p>{fundraising.fundraisingDescription}</p>
      <p>Date: {new Date(fundraising.fundraisingDate).toLocaleDateString()}</p>
    </div>
  );
};

export default FundraisingPage;
