// AdminDashboard.js
import React from 'react';
import AdminRoleAssignment from './AdminRoleAssignment';
import AddAnnouncement from './AddAnnouncement';
import { Link } from 'react-router-dom';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import AddFundraising from './AddFundraising';

const AdminDashboard = () => {
  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4">Admin Dashboard</h1>

      <Row className="mb-4">
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Manage Roles</Card.Title>
              <Card.Text>Assign admin roles to users.</Card.Text>
              <AdminRoleAssignment />
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Manage Announcements</Card.Title>
              <Card.Text>Create or delete announcements.</Card.Text>
              <AddAnnouncement />
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Manage Events</Card.Title>
              <Card.Text>Create or delete events for the PTO.</Card.Text>
              <Link to="/admin/add-event">
                <Button variant="primary">Add New Event</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Manage Fundraising</Card.Title>
              <Card.Text>Create, edit, or delete fundraising events.</Card.Text>
              <Link to="/admin/fundraising">
                <Button variant="secondary" className="mr-2">View All Fundraising</Button>
              </Link>
              <AddFundraising />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminDashboard;
