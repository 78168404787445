import React from 'react';
import './AboutPage.css'; // Import the CSS file

const AboutPage = () => {
  return (
    <div className="container py-5 text-center">
      <div className="row justify-content-center">
        <div className="col-md-8">
          {/* Image Section */}
          <img 
            src="/images/PTO-10_1696983334.webp" 
            alt="RLC PTO Logo" 
            className="img-fluid about-image"
          />

          {/* Text Section */}
          <h1 className="mt-4">About RLC PTO</h1>
          <p>
            The Rosa Lee Carter Elementary School Parent Teacher Organization (PTO) is a non-profit
            organization comprised of dedicated volunteers, including parents, guardians, and
            teachers, who strive to enhance the educational and social environment of our school.
          </p>
          <p>
            Our mission is to foster a strong sense of community by providing support to students,
            staff, and families through a variety of activities and events throughout the school
            year. The PTO sponsors events such as school dances, family nights, and fundraising
            activities that support classroom resources and student programs.
          </p>
          <p>
            The PTO also provides financial support to teachers for classroom supplies and
            educational enhancements. We encourage everyone to get involved in supporting the PTO
            and contributing to the success of our students!
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
