import axios from 'axios';

const ADMIN_API_URL = "http://localhost:8082/admin/fundraising"; // Admin endpoint for creation
const API_URL = "http://localhost:8082/api/fundraising"; // Public API URL for fetching

export const getAllFundraising = () => { 
    return axios.get(API_URL); 
};

// Update the public-facing API fetch method.
export const getFundraisingBySlug = (slug) => { 
    return axios.get(`${API_URL}/${slug}`, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
};

export const getFundraisingById = (id, token) => {
    return axios.get(`${ADMIN_API_URL}/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
  };
  


export const createFundraising = (fundraising, token) => {
    return axios.post(ADMIN_API_URL, fundraising, {
        headers: {
            'Authorization': `Bearer ${token}`,  // Fixed Token formatting issue
            'Content-Type': 'application/json',
        }
    });
};

export const deleteFundraising = (id, token) => {
    return axios.delete(`${ADMIN_API_URL}/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const updateFundraising = (id, updatedData, token) => {
    return axios.put(`${ADMIN_API_URL}/${id}`, updatedData, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
};

export const getAllFundraisingAdmin = (token) => {
    return axios.get(ADMIN_API_URL, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};