import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

const LoginModal = ({ show, handleClose }) => {
  const [isSignUp, setIsSignUp] = useState(false);  // State to toggle between login and signup
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  // Toggle between sign in and sign up forms
  const toggleForm = () => {
    setIsSignUp(!isSignUp);
    setError('');  // Clear any previous error when toggling forms
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const url = isSignUp ? 'http://localhost:8082/auth/register' : 'http://localhost:8082/auth/login';
      const headers = {
        'Content-Type': 'application/json',
      };

      const data = JSON.stringify({
        username: username,
        password: password,
      });

      const response = await axios.post(url, data, { headers });
      console.log(`${isSignUp ? 'Signed up' : 'Logged in'} successfully:`, response.data);

      if (isSignUp) {
        // If sign-up is successful, redirect to the membership page or anywhere else
        window.location.href = '/membership'; 
      } else {
        // Save the JWT token and username in localStorage
        localStorage.setItem('token', response.data.token);
        console.log("Stored username: ", localStorage.getItem('username'));  // Log the stored username

        localStorage.setItem('username', username);  // Store username in localStorage

        // If login is successful, redirect to the admin dashboard
        window.location.href = '/admin-dashboard';  
      }
    } catch (error) {
      console.error(`${isSignUp ? 'Sign up' : 'Login'} failed:`, error);
      setError(`${isSignUp ? 'Sign up' : 'Login'} failed. Please check your credentials.`);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{isSignUp ? 'Sign Up' : 'Admin Login'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            {isSignUp ? 'Sign Up' : 'Login'}
          </Button>
          {error && <p className="text-danger mt-2">{error}</p>}
        </Form>

        {/* Toggle between Login and Sign Up */}
        <div className="mt-3">
          {isSignUp ? (
            <p>
              Already have an account?{' '}
              <span onClick={toggleForm} style={{ cursor: 'pointer', color: 'blue' }}>
                Login
              </span>
            </p>
          ) : (
            <p>
              Don’t have an account?{' '}
              <span onClick={toggleForm} style={{ cursor: 'pointer', color: 'blue' }}>
                Sign Up
              </span>
            </p>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
