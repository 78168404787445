import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddAnnouncement = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState(''); 
  const [publishedDate, setPublishedDate] = useState('');
  const [message, setMessage] = useState('');
  const [token, setToken] = useState(null);

  // Retrieve token from localStorage when the component mounts
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    console.log('Retrieved JWT Token on component mount:', storedToken);
    if (!storedToken) {
      setMessage('Authentication failed. Please log in again.');
      return;
    }
    setToken(storedToken); // Store the token in state
  }, []);

  const handleAddAnnouncement = async () => {
    // Check if the token is present before making the request
    if (!token) {
      console.error('No JWT token found. Please log in again.');
      setMessage('Authentication failed. Please log in again.');
      return;
    }

    console.log('JWT Token before making request:', token);

    if (!title || !content || !publishedDate) {
      setMessage('Please fill in all fields.');
      return;
    }

    try {
      const newAnnouncement = {
        title,
        content, 
        published_date: publishedDate, 
      };

      const response = await axios.post(
        'http://localhost:8082/admin/api/announcements',
        newAnnouncement,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      
      setMessage(`Announcement added successfully! ID: ${response.data.id}`);
      setTitle('');
      setContent('');
      setPublishedDate('');
    } catch (error) {
      console.error('Error adding announcement:', error.response?.data || error.message);
      setMessage('Error adding announcement. Please try again.');
    }
  };

  return (
    <div>
      <h3>Add New Announcement</h3>
      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <textarea
        placeholder="Content"
        value={content}
        onChange={(e) => setContent(e.target.value)}
      />
      <input
        type="date"
        value={publishedDate}
        onChange={(e) => setPublishedDate(e.target.value)}
      />
      <button onClick={handleAddAnnouncement}>Add Announcement</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default AddAnnouncement;
