import React from 'react';
import { useNavigate } from 'react-router-dom';
import './WelcomeSection.css';  // Ensure the CSS file is imported

const WelcomeSection = () => {
  const navigate = useNavigate();

  // Navigate to the About page when the Learn More button is clicked
  const handleLearnMoreClick = () => {
    navigate('/about');
  };

  return (
    <div className="welcome-section">  {/* Using CSS class for styling */}
      <div className="container">
        <h1 className="welcome-title">Welcome to RLC PTO</h1>  {/* Updated class for title */}
        <p className="welcome-text">Making Children Our Priority!</p>  {/* Updated class for text */}
        <button className="btn welcome-btn" onClick={handleLearnMoreClick}>
          Learn More
        </button>
      </div>
    </div>
  );
};

export default WelcomeSection;
