import React, { useState, useEffect } from 'react';
import { createFundraising } from '../services/FundraisingService';

const AddFundraising = () => {
    const [fundraisingTitle, setFundraisingTitle] = useState('');
    const [fundraisingDescription, setFundraisingDescription] = useState('');
    const [fundraisingDate, setFundraisingDate] = useState('');
    const [message, setMessage] = useState('');
    const [token, setToken] = useState(null);

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (!storedToken) {
            setMessage('Authentication failed. Please log in again.');
            return;
        }
        setToken(storedToken);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!token) {
            setMessage('Authentication failed. Please log in again.');
            return;
        }

        const fundraisingData = {
            fundraisingTitle,
            fundraisingDescription,
            fundraisingDate  // Send date only, no time
        };

        try {
            await createFundraising(fundraisingData, token);
            setMessage('Fundraising event created successfully!');
            setFundraisingTitle('');
            setFundraisingDescription('');
            setFundraisingDate('');
        } catch (err) {
            console.error('Error creating fundraising event:', err);
            setMessage('Error creating fundraising event.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>Fundraising Title</label>
                <input 
                    type="text" 
                    value={fundraisingTitle} 
                    onChange={(e) => setFundraisingTitle(e.target.value)} 
                    required 
                />
            </div>
            <div>
                <label>Fundraising Description</label>
                <textarea 
                    value={fundraisingDescription} 
                    onChange={(e) => setFundraisingDescription(e.target.value)} 
                    required 
                />
            </div>
            <div>
                <label>Fundraising Date</label>
                <input 
                    type="date" 
                    value={fundraisingDate} 
                    onChange={(e) => setFundraisingDate(e.target.value)} 
                    required 
                />
            </div>
            <button type="submit">Create Fundraising</button>
            {message && <p>{message}</p>}
        </form>
    );
};

export default AddFundraising;
